import * as ActionTypes from '../constants/ActionTypes';
import {initialState} from '../index';

const userInfo = (state = initialState, action) => {
    console.log("UserInfo");
    console.log(state);

    switch (action.type) {
      case ActionTypes.CHANGE_LANGUAGE:
        return {
          ...state,
          locale: action.locale
        };
      case ActionTypes.SET_JWT_AND_PROFILE:
        return {
          ...state,
          token: action.jwt,
          profile: action.profile
        };
      case ActionTypes.LOGOUT:
        return {
          ...state,
          token: undefined,
          profile: undefined
        };
      default:
        return {
          ...state
        };
    }
  };

export default userInfo;