import * as ActionTypes from '../constants/ActionTypes';
import {initialState} from '../index';
 
const chat = (state = initialState, action) => {
    console.log(action);
  
    switch (action.type) {
      case ActionTypes.CLEAR_MSG:
        return {
          ...state,
          //  lastUpdate: undefined,
          entries: []
        };
      case ActionTypes.ADD_MSG:
        return {
          ...state,
          entries: [
            ...state.entries,
            {
              id: action.agent.params.id,
              sender: action.agent.params.sender,
              name: action.agent.params.name,
              isMe: action.agent.params.isMe,
              send: action.agent.params.send,
              text: action.agent.params.text
            }
          ]
        };
      case ActionTypes.ADD_MSG_SUCCESS:
        {
          const replaceId = action.data.old;
          const updateId = state.lastUpdate > action.data.id ? state.lastUpdate : action.data.id;
          if (state.entries.find(x => x.id === action.data.id) !== undefined) {
            return {
              ...state
            };
          }
          return {
            ...state,
            lastUpdate: updateId,
            entries: state.entries.map(element => {
              if (element.id !== replaceId) {
                return element;
              }
              return {
                id: action.data.id,
                sender: element.sender,
                name: element.name,
                isMe: element.isMe,
                send: true,
                text: element.text
              };
            })
          };
        }
      case ActionTypes.GET_CHATS_SUCCESS:
        {
          if (action.data.chats !== undefined) {
            const botChatId = action.data.chats.find(x => x.name === '@bot').id;
            return {
              ...state,
              botChatId
            };
          }
          return {
            ...state
          };
        }
      case ActionTypes.FETCH_MSG:
        return {
          ...state,
          fetchRunning: true
        };
      case ActionTypes.FETCH_MSG_SUCCESS:
        {
          const lastUpdateId = action.data.entries.length ? action.data.entries[action.data.entries.length - 1].id : state.lastUpdate;
          const updateId = state.lastUpdate > lastUpdateId ? state.lastUpdate : lastUpdateId;
          const chatEntries = action.data.entries.map(element => {
            return {
              id: element.id,
              sender: element.sender,
              name: element.name,
              isMe: element.isMe,
              text: element.text,
              send: true
            };
          });
          return {
            ...state,
            lastUpdate: updateId,
            fetchRunning: false,
            entries: [
              ...state.entries,
              ...chatEntries
            ]
          };
        }
      default:
        return {
          ...state
        };
    }
  };

export default chat;