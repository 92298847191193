import * as ActionTypes from '../constants/ActionTypes';
import {initialState} from '../index';

function filterSensors(sensorList) {
  var sensors = [];
  console.log(sensorList);
  sensorList.forEach(entry => {
    
    if (entry.id.startsWith("ochsner")) {
      entry.location = "Heizung";
    }

    if (entry.location !== 'n/a') {
      console.log(`Grouped sensor found: ${entry.friendlyName}`)
    } else {
      sensors.push(entry);
    }
  });
  return sensors;
}

const dash = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SELECT_KEY:
        return {
          ...state,
          selected: action.key
        };
      case ActionTypes.SELECT_SENSOR:
        return {
          ...state,
          sensorSelected: action.key,
          entries: state.entries.map(entry => {
            if (state.configureId > 0 && entry.id === state.configureId) {
              return {
                id: entry.id,
                type: entry.type,
                config: {
                  id: state.sensors[action.key].id,
                  ts: 0
                }
              };
            }
            return entry;
          })
        };
      case ActionTypes.GET_SENSORS_SUCCESS:
        {
          if (action.data !== undefined) {
            return {
              ...state,
              sensors: filterSensors(action.data)
            };
          }
          return {
            ...state
          };
        }
      case ActionTypes.FETCH_SENSORS:
        return {
          ...state,
          fetchRunning: true
        };
      case ActionTypes.FETCH_SENSORS_SUCCESS:
        {
          let dashValues = {};
          action.data.forEach(element => {
            dashValues = {
              ...dashValues,
              [element.devid]: element.value
            };
          });
          return {
            ...state,
            fetchRunning: false,
            values: {
              ...dashValues
            }
          };
        }
      case ActionTypes.ADD_TO_DASH:
        return {
          ...state,
          entries: [
            ...state.entries,
            {
              id: action.uniqueId,
              type: state.selected
            }
          ]
        };
      case ActionTypes.RM_FROM_DASH:
        return {
          ...state,
          entries: state.entries.filter(element => {
            return element.id !== action.id;
          })
        };
      case ActionTypes.CONF_DASH_ENTRY:
        return {
          ...state,
          configureId: action.id
        };
      default:
        return {
          ...state,
          selected: 0
        };
    }
  };

export default dash;