import * as ActionTypes from '../constants/ActionTypes';
import {initialState} from '../index';

const views = (state = initialState, action) => {
    switch (action.type) {
      default:
        return {
          ...state
        };
    }
  };

export default views;